import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { loadNabolagAction } from '../store/nabolag/actions'
import { getNabolagInfo } from '../store/nabolag/selectors'

const mapStateToProps = (state, ownProps) => {
  if (ownProps.nabolag) {
    return {
      nabolag: ownProps.nabolag,
    }
  }

  return {
    nabolag: getNabolagInfo(state),
  }
}

export const withNabolag = Component => {
  const ExtendedNabolag = props => {
    // Finnkode is often (always?) served as number. Must be converted to string!
    if (props && props.nabolag && props.nabolag.finnkode && typeof props.nabolag.finnkode === 'number') {
      props.nabolag.finnkode = props.nabolag.finnkode.toString();
    }
    return <Component {...props} />;
  }
  ExtendedNabolag.getInitialProps = async ctx => {
    const { query, asPath, store } = ctx
    if (query && query.id && !store.getState().nabolag.loaded) {
      if (query.theme) {
        query.t = query.theme === 'preview' ? 'preview' : 1;
      }
      store.dispatch(loadNabolagAction(query.id, query.t))
    }

    // Set query to context server-side so we get consistent data on server/client
    return {
      ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {}),
      query,
      path: asPath,
    }
  }
  return compose(connect(mapStateToProps))(ExtendedNabolag)
}

export default withNabolag
